import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: 'ipfs',
  initialState: {
  }
  ,
  reducers: {
    willSendJson: (state, action: PayloadAction<any>) => {  },
  }
})

console.log('with slices: ', slice)
export const { actions, reducer }: any = slice
console.log('with reducer a: ', reducer)
export const { 
  willSendJson
} = actions
export const selectors = {
}
