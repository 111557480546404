import { all } from 'redux-saga/effects'
import { sagas as authSagas } from './auth'
import { sagas as redeemSagas } from './redeem'
import { sagas as IPFSSagas } from './ipfs'

function* runAllSagas() {
  console.log('in root saga')
  yield all([
    authSagas(),
    redeemSagas(),
    IPFSSagas()
  ])
}

export default runAllSagas;