import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: 'ui',
  initialState: {
    show: false
  }
  ,
  reducers: {
    willShowWaiting: (state, action: PayloadAction<any>) => { state.show = true },
    willHideWaiting: (state, action: PayloadAction<any>) => { state.show = false },
  }
})

export const { actions, reducer }: any = slice

export const { 
  willShowWaiting,
  willHideWaiting
} = actions

export const selectors = {
  isWaitingToShow: (state) => state.ui.show
}
