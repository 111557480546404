import * as React from 'react';
import ReactPlayer from 'react-player'
import StorageRadixNft from '../contracts/StorageRadixNft.json'
import { actions as RedeemsActions, selectors as RedeemsSelectors } from '../store/slices/redeem'

import { contract } from '../config'
import { useSelector } from 'react-redux';


//Configure contract
const stage: string = process.env.REACT_APP_STAGE !== undefined ? process.env.REACT_APP_STAGE : "dev"
let __CONTRACT_ADDRESS = contract[stage].__CONTRACT_ADDRESS;

const Web3 = require("web3");

export const useWeb3 = () => {
  const [web3, setWeb3]: any = React.useState()

  React.useEffect(() => {
    const provider = Web3.givenProvider
    console.log('provider: ', provider);

    const web3 = new Web3(provider)
    console.log('web3: ', web3);

    // global.web3 = web3
    setWeb3(web3)
  }, [])
  return web3
}

export const BlockchainViewer = ({tokenId}) => {

  const projectData = useSelector(RedeemsSelectors.getProject);

  const web3 = useWeb3();
  const [waiting, setWaiting] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [account, setAccount] = React.useState(undefined as any)
  const [nfts, setNfts] = React.useState([] as any);
  
  console.log('with tokenId: ', tokenId);

  async function handleConnect() {

    setWaiting(true);
    const accounts = await web3.eth.requestAccounts();
    console.log('with accounts: ', accounts)
    setAccount(accounts[0]);
    const account = accounts[0];

    const contract = new web3.eth.Contract(
      JSON.parse(projectData.abi),
      projectData.address
    )

    // let balance = await contract.methods.balanceOf(account).call()
    // console.log('with supply: ', balance)
    // for (let i = 0; i < balance; i++) {
    //   console.log('in for', i, account)
    //   let tokenId = await contract.methods.tokenOfOwnerByIndex(account, i).call()
    //   console.log('with tokenId: ', tokenId);
      let tokenURI = await contract.methods.tokenURI(tokenId).call()
      console.log('with token uri: ', tokenId, tokenURI)

      const result = await (await fetch(tokenURI)).json();
      console.log('with result: ', result);
      setNfts((prevState: any) => {
        console.log('with prevState: ', prevState);
        return [...prevState, result]
      });
    // }
    setLoaded(true);
    setWaiting(false);
  }

  return (
    <div className={`flex flex-col items-center ${!loaded ? "justify-center" : ""} min-h-screen py-2`}>
      { !loaded ? (
        <button
          className="bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 hover:bg-gray-400 text-white font-bold py-4 px-14 rounded inline-flex items-center"
          onClick={handleConnect}
          disabled={waiting}
        >
          {waiting ? (
            <>
              <div className="loader ease-linear rounded-full border-4 border-t-3 border-gray-200 h-6 w-6 mx-2"></div>
              <span>Loading...</span>
            </>
          ) : (
            <>
              <svg className="fill-current w-4 h-4 mx-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" /></svg>
              <span>Load from Blockchain</span>
            </>
          )}
        </button>
      ) : (

        <>
          {/* <p>Address: {account}</p> */}
          <div className="p-10 border-black grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-5">

            {nfts.map((nft: any) => (
              <div className="w-200 rounded overflow-hidden shadow-lg">
                {nft.animation_url !== undefined ? (
                  <div className="w-200">
                    <ReactPlayer light={nft.image} width="200" controls={true} url={`${nft.animation_url}`} />
                  </div>
                ) : (
                  <img className="w-200" src={nft.image} alt="Mountain" />
                )}
                <div className="px-6 py-4">
                  <div className="font-bold text-xl text-center mb-2">{nft.title}</div>
                  <p className="text-gray-700 text-base text-center">{nft.description}</p>
                </div>
                <div className="flex px-6 pt-4 pb-2 justify-center items-center">
                  {nft.attributes.map((attr: any)=>{
                    return (
                      <span id={attr.trait_type} className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">{attr.value}</span>
                    )
                  })}
                </div>
              </div>
            ))}
          </div>

        </>
      )
      }

    </div >
  )
}