// const pinataSDK = require('@pinata/sdk');
// const pinata = pinataSDK('1071bf887d339a77b55e', '4b0145bbac51ebc439488501b8bce5a34dbf623257e2bbaac4d3532ce10dec63');

import Amplify from "aws-amplify";
import { API, graphqlOperation } from 'aws-amplify';
import { loader } from 'graphql.macro';
import gql from 'graphql-tag';
import { Rowing } from "@material-ui/icons";

const myAppConfig = {
  // ...
  'aws_appsync_graphqlEndpoint': 'https://hqfmd4ozhfdnlhjbcsjrygsebi.appsync-api.eu-west-1.amazonaws.com/graphql',
  'aws_appsync_region': 'eu-west-1',
  'aws_appsync_authenticationType': 'API_KEY',
  'aws_appsync_apiKey': 'da2-ucjvygxurnde5inxq5vcqz7nei',
  // ...
}

const pinJSONMutation = gql`
  mutation mutation($templateName: String, $data: AWSJSON) {
    pinJSON(project: "frankstudio", templateName: $templateName, data: $data)
}
`

export const pinJson = async (row: any) => {
  // const result = await pinata.pinJSONToIPFS(data, {})
  const result: any = await API.graphql({ query: pinJSONMutation, variables: {templateName: row.name, data: row.metadata} });
  return result.data.pinJSON;
  console.log('with result');
  return {};
}