import styles from '../styles/Home.module.css'
import * as React from 'react';
import { DataGrid, GridColDef, GridValueGetterParams } from '@material-ui/data-grid';
import Drawer from '@material-ui/core/Drawer';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { useDispatch, useSelector } from 'react-redux'
import { actions as RedeemsActions, selectors as RedeemsSelectors } from '../store/slices/redeem'
import Button from '@material-ui/core/Button';
import {
  BrowserRouter as Router,
  Switch,
  useLocation,
  Link
} from "react-router-dom";
const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3),
    },
  }),
);

export const SiteDrawer = () => {

  const classes = useStyles();

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <div className={classes.toolbar}>
        <Toolbar>
          <Typography variant="h6" noWrap>
            Tomato
        </Typography>
        </Toolbar>
      </div>
      <Divider />
      <List>
        {/* {[{ title: 'Redeems', path: 'dashboard' }, { title: 'Templates', path: 'templates' }].map((obj, index) => ( */}
        {[{ title: 'Redeems', path: 'dashboard' }].map((obj, index) => (
          <ListItem button key={obj.path}>
            <ListItemIcon>{index % 2 === 1 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
            <Link to={obj.path}>
              {obj.title}
            </Link>
          </ListItem>
        ))}
      </List>
    </Drawer>
  )
}
