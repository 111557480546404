import React, { useReducer } from "react";

import { Provider, useSelector } from 'react-redux'
import { configureStore } from './store'
import { AppRouter } from './router';
import { WaitingDialog } from './components/waiting';
// import { NotificationLayer } from './notification';
import { actions as UIActions, selectors as UISelectors } from './store/slices/ui';
export default function App() {
  return (
    <Provider store={configureStore({})}>
      {/* <NotificationLayer> */}

      <DialogApp />
      {/* </NotificationLayer> */}
    </Provider >
  );
}

const DialogApp = () => {

  const isWaitingToShow = useSelector(UISelectors.isWaitingToShow);

  return (
    <>
      <AppRouter />
      <WaitingDialog open={isWaitingToShow} />
    </>
  )
}
