import { call, put, takeLatest, select, fork } from 'redux-saga/effects'
import { actions as IPFSActions, selectors as IPFSSelectors } from '../slices/ipfs'
import * as IPFSApi  from '../api/ipfs'

export function* sagas() {
  yield takeLatest(IPFSActions.willSendJson.type, willSendJson);
}

function* willSendJson(action: any) {
  const result = yield call(IPFSApi.pinJson, action.payload);
  console.log('with willSendJson result: ', result);

}
