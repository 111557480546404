import Amplify from "aws-amplify";
import { API, graphqlOperation } from 'aws-amplify';
import { loader } from 'graphql.macro';
import gql from 'graphql-tag';
import Web3 from "web3"
import StorageRadixNft from '../../contracts/StorageRadixNft.json'
// const parse = require("json-templates");
import { configuration, contract } from '../../config'
import { orderBy, reduce } from 'lodash';

//Configure contract
// const stage: string = process.env.REACT_APP_STAGE !== undefined ? process.env.REACT_APP_STAGE : "dev"
// let __CONTRACT_ADDRESS = contract[stage].__CONTRACT_ADDRESS;

const listRedeemQuery = gql`
  query listRedeems($project: String) {
    listRedeems(project: $project){
      emailAddress
      project
      redeemCode
      template
      status
      address
      creationDate
      updateDate
      transaction
      drop
      cid
      tokenId
  }
}
`

const listTemplatesQuery = gql`
  query listTemplate($project: String) {
    listTemplates(project: $project){
      name
      metadata
      project
      creationDate
      cid
    }
  }
`

const updateTemplateMutation = gql`
  mutation updateTemplate($project: String, $name: String, $metadata: AWSJSON){
    updateNftTemplate(project: $project, name: $name, metadata: $metadata){
      name
      metadata
      project
      creationDate
      cid      
    }
  }
`

const mintRequestMutation = gql`
  mutation Mint($project: String, $redeem: String, $success: Boolean, $transaction: String) {
    mintRequest(
      project: $project,
      redeem: $redeem
      success: $success,
      transaction: $transaction,
    ){
      emailAddress
      project
      redeemCode
      template
      status
      address
      creationDate
      updateDate
      transaction
      tokenId
  }
}
`

const assignRedeemsMutation = gql`
  mutation assignRedeems($project: String, $redeems: [RedeemAsign]){
    assignRedeems(
      project: $project,
      redeems: $redeems
    ){
      emailAddress
      project
      redeemCode
      template
      status
      address
      creationDate
      updateDate
      transaction
      tokenId
      isValid
    }
  }
`

const checkTransactionQuery = gql`
  query checkTransaction($project: String, $redeem: String) {
    checkTransaction(
      project: $project,
      redeem: $redeem
    ){
      emailAddress
      project
      redeemCode
      template
      status
      address
      creationDate
      updateDate
      transaction
      tokenId
      isValid
    }
  }
`

const getProjectQuery = gql`
  query getProject($project: String){
    getProject(project: $project){
    project
    address
    network
    abi
  }}
`

export const listRedeems = async (project) => {

  const result: any = await API.graphql({ query: listRedeemQuery, variables: {project} });
  return result.data.listRedeems;
}

export const getProject = async (project) => {
  const result: any = await API.graphql({ query: getProjectQuery, variables: { project } });
  return result.data.getProject;

}

export const listTemplates = async () => {

  const result: any = await API.graphql({ query: listTemplatesQuery, variables: {} });
  return result.data.listTemplates;
}

export const updateTemplate = async (project, name, metadata) => {

  console.log('with data: ', project, name, metadata)
  const result: any = await API.graphql({ query: updateTemplateMutation, variables: { project, name, metadata } });
  return result.data.updateNftTemplate;
}

export const mintAsset = async (request, project) => {

  console.log('with request: ', request);
  const provider = Web3.givenProvider
  console.log('provider: ', provider);

  const web3 = new Web3(provider)
  console.log('web3: ', web3);

  const accounts = await web3.eth.requestAccounts();
  console.log('with accounts: ', accounts);
  const txCount = await web3.eth.getTransactionCount(accounts[0], 'pending');
  console.log('with tx count: ', txCount);


  const nonce = txCount+1;

  const contract = new web3.eth.Contract(
    JSON.parse(project.abi),
    project.address
  )

  const count = await contract.methods.totalSupply().call()
  console.log('found ', count);
  const tokenUri = request.cid

  // const tx = contract.methods.awardItem(address, tokenUri, tokenUri)
  // const rawData = tx.encodeABI()
  // const signedTx = web3.eth.signTransaction({from: accounts[0], data: rawData});
  // console.log('with signed tx:', signedTx)
  // const tx = contract.methods.awardItem(address, tokenUri, tokenUri)
  // const rawData = tx.encodeABI()
  // console.log('with tx: ', rawData);
  // web3.eth.sendTransaction({from: accounts[0], data: rawData});

  let transactionHash = ""
  let success = false
  try {
    console.log('in try')
    // const mintResult = await contract.methods.mint(request.address, tokenUri).send({ from: accounts[0] })
    const mintResult: any = await _mint(contract, request.address, tokenUri, accounts[0], nonce)
    console.log('with mint result: ', mintResult);
    success = true
    transactionHash = mintResult;
    // const tokenId = parseInt(mintResult.events.Transfer.returnValues.tokenId);
    // console.log('with tokenId: ', tokenId);

    const result: any = await API.graphql({
      query: mintRequestMutation,
      variables: {
        project: project.project,
        redeem: request.redeemCode,
        success,
        transaction: transactionHash
      }
    });

    console.log('with result: ', result);

  } catch (error) {

    console.log('with error: ', error.message, error.receipt);
    transactionHash = error.receipt.transactionHash;
    const result: any = await API.graphql({
      query: mintRequestMutation,
      variables: {
        project: project.project,
        redeem: request.redeemCode,
        success,
        transaction: transactionHash
      }
    });
    console.log('with result: ', result);

  }

}

export const assignRedeems = async (project: string, redeems: any) => {
  const result: any = await API.graphql({ query: assignRedeemsMutation, variables: { project, redeems } });
  return result.data.assignRedeems;
}

export const checkTransaction = async (project: string, redeem: string) => {
  const result: any = await API.graphql({ query: checkTransactionQuery, variables: { project, redeem } });
  return result.data.checkTransaction;
}

const _mint = (contract, address, uri, from, nonce) => {
  return new Promise((resolve, reject) => {
    contract.methods.mint(address, uri).send({ from: from, nonce: nonce })
      .on('transactionHash', function (hash) {
        console.log('with transactionHash: ', hash)
        resolve(hash)
      })
      .on('confirmation', function (confirmationNumber, receipt) {
        console.log('with confirmation: ', confirmationNumber, receipt);
      })
      .on('receipt', function (receipt) {
        console.log('with receipt: ', receipt)

      })
  })
}

export const willDownloadRedeems = (redeems) => {

  const ordered = orderBy(redeems, ['drop']);
  const codes = ordered.map((request) => request.redeemCode)
  console.log('with redeems: ', orderBy(redeems, ['drop']));
  console.log('with codes: ', codes);
  const codeStr = reduce(codes, function (sum, n) { return `${sum}\n${n}` })
  const element = document.createElement("a");
  const file = new Blob([codeStr], { type: 'text/plain' });
  element.href = URL.createObjectURL(file);
  element.download = "redeems.csv";
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
}

export const willDownloadMetadata = async (cid) => {
  console.log('in willDownloadMetadata with: ', cid)
  const metadata = await (await (await fetch(`https://gateway.pinata.cloud/ipfs/${cid}`)).json());
  console.log('with metadata: ', metadata)
  return metadata;
}