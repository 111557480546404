export const configuration: any = {
  dev: {
    Auth: {

      // REQUIRED - Amazon Cognito Region
      region: 'eu-west-1',

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'eu-west-1_KfJPwL9At',

      userPoolWebClientId: '5dl0j56fi60unutvnkvee8rql7',

      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: false

    },
    'aws_appsync_graphqlEndpoint': 'https://hqfmd4ozhfdnlhjbcsjrygsebi.appsync-api.eu-west-1.amazonaws.com/graphql',
    'aws_appsync_region': 'eu-west-1',
    'aws_appsync_authenticationType': 'AMAZON_COGNITO_USER_POOLS',
  },
  prod: {
    Auth: {
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_q5hMel9Gh',
      userPoolWebClientId: '3r5knhflbtk7uuq75tpk2knebl',
      mandatorySignIn: false
    },
    'aws_appsync_graphqlEndpoint': 'https://k2nzzruhgfadlchll5fq7dqkn4.appsync-api.eu-west-1.amazonaws.com/graphql',
    'aws_appsync_region': 'eu-west-1',
    'aws_appsync_authenticationType': 'AMAZON_COGNITO_USER_POOLS'    
  }
}

export const contract: any = {
  dev: {
    __CONTRACT_ADDRESS: '0xCc896b360A88651deF9a088d03f9a634Ee83f705',
    blockExplorer: 'https://ropsten.etherscan.io'
  },
  prod: {
    __CONTRACT_ADDRESS: '0x4b7b6DF5376B5C034a0471937d76191aF8717A7f',
    blockExplorer: 'https://rinkeby.etherscan.io'
  }
}
