import styles from '../styles/Home.module.css'
import * as React from 'react';
import { DataGrid, GridColDef, GridValueGetterParams, GridSortDirection, GridValueFormatterParams } from '@material-ui/data-grid';
import Drawer from '@material-ui/core/Drawer';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { useDispatch, useSelector } from 'react-redux'
import { actions as RedeemsActions, selectors as RedeemsSelectors } from '../store/slices/redeem'
import Button from '@material-ui/core/Button';
import { DrawerLayout } from '../layouts/drawer'
import { AssignMultiPopup } from '../components/assignMulti';
import { AssignSinglePopup } from '../components/assignSingle';
import { ViewNftPopup } from '../components/viewNft';
import { useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { StylesProvider } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import { configuration, contract } from '../config'
import { useParams } from "react-router-dom";

const stage: string = process.env.REACT_APP_STAGE !== undefined ? process.env.REACT_APP_STAGE : "dev"
let blockExplorer = contract[stage].blockExplorer;
const { DateTime } = require("luxon");

const rows = [];

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3),
    },
  }),
);

export const Dashboard = () => {

  let { project } = useParams();

  const checkingRequest = useSelector(RedeemsSelectors.getCheckingRequest);
  console.log('with checkingRequest: ', checkingRequest)
  const theme = useTheme();

  const columns: GridColDef[] = [
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      width: 160,
      renderCell: (cellValues: any) => {
        // console.log('with cellValues: ', cellValues);
        const row = cellValues.row;
        // console.log('checking request test: ', checkingRequest[row.redeemCode]);
        return (
          <>
            {row.status === "minted" &&
              <Button
                variant="contained"
                style={{ backgroundColor: theme.palette.primary.light, color: 'white' }}
                onClick={() => {
                  setSelectedRow(row);
                  dispatch(RedeemsActions.willShowMetadata(row));
                  setViewNftOpenedIPFSOnly(false);
                  setViewNftOpened(true);
                }}
              >View</Button>
            }
            {row.status === "stored" &&
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setSelectedRow(row);
                  setAssignSingleOpened(true);
                }}
              >Assign</Button>
            }
            {row.status === "pending" &&
              <StylesProvider injectFirst>
                {checkingRequest[row.redeemCode] === undefined ? (
                  <Button
                    variant="contained"
                    style={{ backgroundColor: theme.palette.secondary.dark, color: 'white' }}

                    onClick={() => {
                      dispatch(RedeemsActions.willCheckMint(row));
                    }}
                  >Check</Button>
                ) : (
                  <Button
                    disabled
                    variant="contained"
                    style={{ backgroundColor: theme.palette.secondary.dark, color: 'white' }}

                    onClick={() => {
                    }}
                  >Checking<CircularProgress className="ml-4" size={24} style={{ color: 'white' }} /></Button>
                )}

              </StylesProvider>
            }
            {row.status === "confirmed" || row.status === "error" ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  dispatch(RedeemsActions.willMintAsset(row));
                }}
              >
                Mint
              </Button>
            ) : (
              <></>
            )}
          </>
        )
      }
    },
    { field: 'project', headerName: 'Project', width: 120 },
    { field: 'redeemCode', headerName: 'Redeem', width: 120 },
    { field: 'template', headerName: 'Template', width: 120 },
    { field: 'status', headerName: 'Status', width: 120 },
    { field: 'drop', headerName: 'Drop', width: 120 },
    {
      field: 'cid', headerName: 'IPFS CID', width: 450,
      renderCell: (cellValues: any) => {
        // console.log('with cellValues: ', cellValues);
        const row = cellValues.row;
        if (row.status !== 'draft') {
          return <a href="#test" onClick={(e) => {
            e.stopPropagation();
            setSelectedRow(row);
            dispatch(RedeemsActions.willShowMetadata(row));
            setViewNftOpenedIPFSOnly(true);
            setViewNftOpened(true);
          }}>{row.cid}</a>
        } else {
          return <p>{row.cid}</p>
        }
      }
    },
    {
      field: 'updateDate', headerName: 'Last Update', width: 250,
      valueFormatter: (params: GridValueFormatterParams) => {
        if (params.value) {
          return DateTime.fromISO(params.value).setZone("Europe/Rome").toLocaleString(DateTime.DATETIME_FULL);
        } else {
          return '';
        }
      }
    },
    { field: 'address', headerName: 'Chain Address', width: 400 },
    { field: 'tokenId', headerName: 'TokenId', width: 200 },
    {
      field: 'transaction',
      headerName: 'Transaction',
      width: 600,
      renderCell: (cellValues: any) => {
        return (
          <a target="_blank" rel="noreferrer" href={`${blockExplorer}/tx/${cellValues.row.transaction}`}>{cellValues.row.transaction}</a>
        )
      }
    },

  ];

  const classes = useStyles();
  const dispatch = useDispatch();
  const [assignMultiOpened, setAssignMultiOpened] = React.useState(false);
  const [assignSingleOpened, setAssignSingleOpened] = React.useState(false);
  const [viewNftOpened, setViewNftOpened] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [viewNftOpenedIPFSOnly, setViewNftOpenedIPFSOnly] = React.useState(false);
  const redeems = useSelector(RedeemsSelectors.getRedeems);
  const projectData = useSelector(RedeemsSelectors.getProject);

  const [sortModel, setSortModel] = React.useState([{
    field: 'drop',
    sort: 'asc' as GridSortDirection,
  }])
  React.useEffect(() => {
    dispatch(RedeemsActions.willListRedeems(project));
    dispatch(RedeemsActions.willGetProject(project));
  }, [])

  return (
    <DrawerLayout project={projectData} assignMulti={() => {
      setAssignMultiOpened(true);
    }}>
      <div style={{ width: '100%', height: '100%' }}>
        <DataGrid
          disableSelectionOnClick={false}
          getRowId={(row) => row.redeemCode}
          rows={redeems}
          columns={columns}
          pageSize={100}
          rowHeight={60}
          checkboxSelection
          isRowSelectable={() => false}
          sortModel={
            sortModel
          }
          // sortingMode="server"
          onSortModelChange={(model) => {
            console.log('with new model: ', model);
            setSortModel(model.sortModel)
          }}
        />
      </div>
      <AssignMultiPopup onClose={() => {
        setAssignMultiOpened(false);
      }} selectedValue={{}} open={assignMultiOpened} project={project} />
      <AssignSinglePopup onClose={() => {
        setAssignSingleOpened(false);
      }} selectedValue={selectedRow} open={assignSingleOpened} project={project} />
      <ViewNftPopup IPFSOnly={viewNftOpenedIPFSOnly} onClose={() => {
        setViewNftOpened(false);
      }} open={viewNftOpened} selectedValue={selectedRow} />
    </DrawerLayout>
  )
}