
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { actions as AuthActions } from '../store/slices/auth';

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string()
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
});

export const Login = () => {

  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues: {
      email: 'paneb.devel@gmail.com',
      password: 'PippoPluto80!',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      dispatch(AuthActions.willLoginUser({email: values.email, password: values.password}));
    },
  });

  return (
    <div className={`flex flex-col items-center justify-center min-h-screen py-2`}>
      <Card>
        <form onSubmit={formik.handleSubmit}>
          <CardContent className="flex flex-col mx-2 my-4 w-96">
            <Typography className="text-center" color="textSecondary" gutterBottom>
              <b>TO.MA.TO</b>
            </Typography>
            <Box className="mb-2">
              <TextField
                id="email"
                name="email"
                label="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                variant="outlined"
                fullWidth
              />
            </Box>
            <TextField
              id="password"
              name="password"
              label="Password"
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              variant="outlined"
              fullWidth
            />
          </CardContent>
          <CardActions className="flex flex-col items-center justify-center mx-4">
            <Button variant="outlined" color="primary" fullWidth type="submit">Login</Button>
          </CardActions>
        </form>

      </Card>
    </div>
  )
}
