// import styles from '../styles/Home.module.css'
import * as React from 'react';
import { DataGrid, GridColDef, GridValueGetterParams } from '@material-ui/data-grid';
import Drawer from '@material-ui/core/Drawer';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { useDispatch, useSelector } from 'react-redux'
import { actions as RedeemsActions, selectors as RedeemsSelectors } from '../store/slices/redeem'
import { actions as IPFSActions, selectors as IPFSSelectors } from '../store/slices/ipfs'
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Description from '@material-ui/icons/Description';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import { Redeem } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';
import { BlockchainViewer } from './blockchainViewer';

export interface ViewNftProps {
  open: boolean;
  onClose: (value: string) => void;
  selectedValue: any;
  IPFSOnly: boolean;
}

// const useStyles = makeStyles({

// });

export const ViewNftPopup = (props: ViewNftProps) => {

  // const classes = useStyles();
  const { IPFSOnly, onClose, selectedValue, open } = props;
  const [value, setValue] = React.useState({} as any);
  const dispatch = useDispatch();
  const metadata = useSelector(RedeemsSelectors.getCurrentMetadata);
  const handleClose = () => {
    onClose(selectedValue);
  };
  console.log('selectedValues: ', selectedValue)

  return (
    <Dialog style={{ margin: 40 }} fullScreen onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">
        <div style={{ float: 'left' }}>Show Nft for {selectedValue?.project} - <b>{selectedValue?.template} - {selectedValue?.drop} - (TokenID: {selectedValue?.tokenId})</b></div>
        <div style={{ float: 'right' }}>
          <Button
            variant="outlined"
            onClick={handleClose}
          >Close</Button>
        </div>
      </DialogTitle>

      <Grid container spacing={3}>
        <Grid item xs={IPFSOnly ? 12 : 6}>
          <JSONInput
            id='a_unique_id'
            placeholder={metadata}
            // colors      = { darktheme }
            locale={locale}
            height='100%'
            width='100%'
            viewOnly
            onChange={(value) => setValue(value)}
          />
        </Grid>
        {!IPFSOnly &&
          <Grid item xs={6}>
            <BlockchainViewer tokenId={selectedValue?.tokenId} />
          </Grid>
        }
      </Grid>

    </Dialog>
  )
}