import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory
} from "react-router-dom";
import { useSelector } from "react-redux";

import { history } from './store'
import { ConnectedRouter } from 'connected-react-router'

import { Login } from './pages/login'
import { Dashboard } from './pages/dashboard'

import * as AuthApi from './store/api/auth'
import { selectors as AuthSelectors } from './store/slices/auth'

const PrivateRoute = ({ children, ...rest }: any) => {

  let history = useHistory();
  const isLogged = useSelector(AuthSelectors.isLogged)
  const isChecked = useSelector(AuthSelectors.isChecked)

  return (
    <Route
      {...rest}
      render={
        (props: any) => {
          console.log("isLogged: ", isLogged)
          return (
            <>
              {isChecked ? (
                <>
                  {isLogged ? (
                    children

                  ) : (
                    <Redirect to="/login" />
                  )}
                </>
              ) : (
                <p>waiting</p>
              )
              }

            </>
          )
        }
      }
    />
  )
}

export const AppRouter = () => {

  const isLogged = useSelector(AuthSelectors.isLogged)

  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <PrivateRoute path="/dashboard/:project">
          <Dashboard />
        </PrivateRoute>        
        <PrivateRoute path="/">
          <Dashboard />
        </PrivateRoute>
      </Switch>
    </ConnectedRouter>
  )
}
