import { combineReducers } from 'redux'

import { connectRouter } from 'connected-react-router'
import { reducer as authReducer } from './slices/auth'
import { reducer as minterReducer } from './slices/redeem'
import { reducer as uiReducer } from './slices/ui'

export const createRootReducer = (history: any) => combineReducers({
  router: connectRouter(history),
  auth: authReducer,
  minter: minterReducer,
  ui: uiReducer
})