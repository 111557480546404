// import styles from '../styles/Home.module.css'
import * as React from 'react';
import { DataGrid, GridColDef, GridValueGetterParams } from '@material-ui/data-grid';
import Drawer from '@material-ui/core/Drawer';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { useDispatch, useSelector } from 'react-redux'
import { actions as RedeemsActions, selectors as RedeemsSelectors } from '../store/slices/redeem'
import { actions as IPFSActions, selectors as IPFSSelectors } from '../store/slices/ipfs'
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Description from '@material-ui/icons/Description';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import { Redeem } from '@material-ui/icons';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useFormik } from 'formik';
import * as yup from 'yup';

export interface AssignSinglePopupProps {
  open: boolean;
  onClose: (value: string) => void;
  selectedValue: any;
  project: string;
}

export const AssignSinglePopup = (props: AssignSinglePopupProps) => {

  // const classes = useStyles();
  const { onClose, selectedValue, open } = props;
  const [value, setValue] = React.useState({} as any);
  const dispatch = useDispatch();
  const project = props.project;
  const handleClose = () => {
    onClose(selectedValue);
  };
  // console.log('selectedValues: ', selectedValue)

  const validationSchema = yup.object({
    address: yup
      .string()
      .required('Redeeems is required')
  });

  const formik = useFormik({
    initialValues: {
      address: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log('in onSubmit with ', values);
      // alert(JSON.stringify(values, null, 2));
      const redeems = `${selectedValue.redeemCode}:${values.address}`
      dispatch(RedeemsActions.willAssignRedeems({ project: props.project, redeems: redeems }));
      values.address = '';
      handleClose();
    },
  });

  React.useEffect(()=>{
    if(open){

    }
  }, [open])

  return (
    <Dialog maxWidth="sm" fullWidth onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle id="simple-dialog-title">
          <div style={{ float: 'left' }}>Assign For Redeem</div>

        </DialogTitle>
        <Box className="mx-4 mt-10 mb-10">
          <Box>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <p>Project: <b>{selectedValue && selectedValue.project}</b></p>
              </Grid>
              <Grid item xs={6}>
                <p>Redeem Code: <b>{selectedValue && selectedValue.redeemCode}</b></p>
              </Grid>
              <Grid item xs={6}>
                <p>Template: <b>{selectedValue && selectedValue.template}</b></p>
              </Grid>
              <Grid item xs={6}>
                <p>Drop Number: <b>{selectedValue && selectedValue.drop}</b></p>
              </Grid>
            </Grid>
          </Box>
          <Box className="mt-4">
          <TextField
            variant="outlined" name="address" id="standard-basic" label="Enter address" fullWidth
            value={formik.values.address}
            onChange={formik.handleChange}
            error={formik.touched.address && Boolean(formik.errors.address)}
            helperText={formik.touched.address && formik.errors.address}
          />
          </Box>
          <Box className="mt-4">
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  fullWidth
                  style={{ marginRight: 10 }}
                  size="large"
                >Assign</Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  onClick={handleClose}
                  fullWidth
                  size="large"
                >Undo</Button>
              </Grid>
            </Grid>

          </Box>
        </Box>
      </form>
    </Dialog>
  )
}