export const getBlockExplorerUri = (name) => {
  switch (name) {
    case 'mainnet':
      return 'https://etherscan.io'
    case 'ropsten':
      return 'https://ropsten.etherscan.io'
    case 'rinkeby':
      return 'https://rinkeby.etherscan.io'
    case 'bsc':
      return 'https://bscscan.com/'
    case 'bsc-testnet':
      return 'https://testnet.bscscan.com/'
    default:
      return 'https://ropsten.etherscan.io'
  }
}

export const getBlockApiUri = (name, params) => {
  switch (name) {
    case 'mainnet':
      return `https://mainnet.infura.io/v3/${params.INFURA_PROJECT_ID}`
    case 'ropsten':
      return `https://ropsten.infura.io/v3/${params.INFURA_PROJECT_ID}`
    case 'kovan': 
      return `https://kovan.infura.io/v3/${params.INFURA_PROJECT_ID}`
    case 'rinkeby':
      return `https://rinkeby.infura.io/v3/${params.INFURA_PROJECT_ID}`
    case 'goerli':
      return `https://goerli.infura.io/v3/${params.INFURA_PROJECT_ID}`
    case 'bsc':
      return 'https://bsc-dataseed1.binance.org'
    case 'bsc-testnet':
      return 'https://data-seed-prebsc-1-s1.binance.org'
    default:
      return `https://ropsten.infura.io/v3/${params.INFURA_PROJECT_ID}`
  }
}