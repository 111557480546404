import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: 'redeem',
  initialState: {
    redeems: [],
    templates: [],
    checkingRequests: {} as any,
    currentMetadata: {},
    project: {}
  }
  ,
  reducers: {
    willListRedeems: (state, action: PayloadAction<any>) => {  },
    didListRedeems: (state, action: PayloadAction<any>) => { state.redeems = action.payload },
    willListTemplates: (state, action: PayloadAction<any>) => {  },
    didListTemplates: (state, action: PayloadAction<any>) => { state.templates = action.payload },
    willUpdateTemplate: (state, action: PayloadAction<any>) => {  },
    didUpdateTemplate: (state, action: PayloadAction<any>) => {
      for(let i= 0; i<state.templates.length; i++){
        const template: any = state.templates[i];
        if(template.name == action.payload.name){
          template.metadata = action.payload.metadata
        }
      }
    },
    willMintAsset: (state, action: PayloadAction<any>) => {  },
    willAssignRedeems: (state, action: PayloadAction<any>) => {  },
    willCheckMint: (state, action: PayloadAction<any>) => { state.checkingRequests[action.payload.redeemCode] = true },
    didCheckMintWithFail: (state, action: PayloadAction<any>) => { delete state.checkingRequests[action.payload.redeemCode] },
    willDownloadRedeems: (state, action: PayloadAction<any>) => {  },
    willShowMetadata: (state, action: PayloadAction<any>) => {  },
    didShowMetadata: (state, action: PayloadAction<any>) => { state.currentMetadata = action.payload },
    willHideMetadata: (state, action: PayloadAction<any>) => { state.currentMetadata = {} },
    willGetProject: (state, action: PayloadAction<any>) => {  },
    didGetProject: (state, action: PayloadAction<any>) => { state.project = action.payload },
  }
})

console.log('with slices: ', slice)
export const { actions, reducer }: any = slice
console.log('with reducer a: ', reducer)
export const {
  willListRedeems,
  didListRedeems,
  willListTemplates,
  didListTemplates,
  willUpdateTemplate,
  didUpdateTemplate,
  willMintAsset,
  willAssignRedeems,
  willGetProject,
  didGetProject
} = actions
export const selectors = {
  getRedeems: (state: any) => state.minter.redeems,
  getProject: (state: any) => state.minter.project,
  getCurrentMetadata: (state: any) => state.minter.currentMetadata,
  getCheckingRequest: (state: any) => state.minter.checkingRequests,
  getTemplates: (state: any) => state.minter.templates,
  getTemplate: (state: any, name: string) => {

    console.log('in get template with: ', state, name)
    for(let template of state.minter.templates){
      if (template.name === name ){
        return template
      }
    }
    return undefined
  }
}
